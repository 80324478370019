.contactBanner{
    background-image: url(../../../public/images/footer.svg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 90px 0;
    overflow: hidden;
    @media screen and (max-width:992px){
        padding: 50px 0;
       }
    .title{
        text-align: center;
        margin:0 auto 0;
        h2{
            font-size: 48px;
            color: #000;
            margin-bottom: 25px;
            @media screen and (max-width:1200px){
                font-size: 38px;
                line-height: 55px;
              }
        }
        p{
            font-size: 16px;
            color: #57C1FF;
            margin: 0;
        }
    }
}
.contactForm{
    padding: 70px 0;
    .contactFormLeft{
        margin-bottom: 30px;
        span{
            font-size: 16px;
            color: #57C1FF;
            margin-bottom: 10px;
            display: block;
        }
        h2{
            font-size: 36px;
            color: #000;
            margin-bottom: 20px;
            position: relative;
            @media screen and (max-width:1200px){
                font-size: 30px;
                line-height: 45px;
              }
              &::before{
                content: '';
                position: absolute;
                left: 0;
                bottom: -10px;
                width: 50px;
                height: 2px;
                background-color: #57C1FF;
              }
        }
        p{
            font-size: 16px;
            color: #00000077;
            margin-bottom: 30px;
        }
        .contactFormLeftContent{
            .block{
                display: flex;
                margin-bottom: 20px;
                align-items: center;
                img{
                    margin-right: 10px;
                }
                .content{
                    h4{
                        font-size: 18px;
                        color: #000;
                        margin-bottom: 5px;
                    }
                    p{
                        font-size: 16px;
                        color: #00000077;
                        margin: 0;
                        a{
                            color: #00000077;
                            text-decoration: none;
                            &:hover{
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }
    .formData{
        background-color: #fff;
        padding: 40px;
        border-radius: 10px;
        box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 9%);
        @media screen and (max-width:992px){
            padding: 20px;
           }
        form{
            h4{
                font-size: 24px;
                color: #000;
                @media screen and (max-width:1200px){
                    font-size: 20px;
                    line-height: 30px;
                  }
            }
            .form-group{
                margin-top: 20px;
                position: relative;
                label{
                    font-size: 16px;
                    color: #000;
                    font-weight: 500;
                    margin-bottom: 10px;
                    display: block;
                }
                input, textarea{
                    width: 100%;
                    padding: 15px;
                    border: 1px solid #00000033;
                    border-radius: 5px;
                    font-size: 16px;
                    color: #000;
                    outline: none;
                    &::placeholder{
                        color: #00000077;
                    }
                }
                textarea{
                    height: 150px;
                }
                button{
                    background-color: #57C1FF;
                    color: #fff;
                    border: 1px solid #57C1FF;
                    padding: 15px 30px;
                    border: none;
                    border-radius: 5px;
                    font-size: 16px;
                    cursor: pointer;
                    transition: all 0.3s;
                    width: 100%;
                    &:hover{
                        background-color: #fff;
                        color: #57C1FF;
                        border: 1px solid #57C1FF;
                    }
                }
            }

        }
    }
    .map{
        padding-top: 50px;
    }
}