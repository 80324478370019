@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');
* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}

body {
  margin: 80px 0 0;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: none;
  overflow-x: clip;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Space Grotesk", sans-serif;
  background-color: #fff;
  color: #000;
}


/* @media (min-width: 2560px){ 
  html { 
    zoom: 150%;
    -ms-zoom: 1.5;
    -webkit-zoom: 1.5;
    -moz-transform:  scale(1.5,1.5);        
    transform-origin: center top ;
  } 
  body { 
    zoom: 1.5;        
  }
} */
select:focus{
  outline: none !important;
  box-shadow: none !important;
}
a{
  text-decoration: none !important;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Space Grotesk", sans-serif;
  font-weight: bold;
}
