.pricingBanner{
    background-image: url(../../../public/images/footer.svg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 120px 0 0;
    overflow: hidden;
    @media screen and (max-width:992px){
        padding: 70px 0 0;
       }
    .title{
        text-align: center;
        margin:0 auto 0;
        h2{
            font-size: 48px;
            color: #000;
            margin-bottom: 25px;
            @media screen and (max-width:1200px){
                font-size: 38px;
                line-height: 55px;
              }
        }
        p{
            font-size: 16px;
            color: #57C1FF;
            margin: 0;
        }
        h3{
            font-size: 48px;
            color: #000;
            margin-bottom: 0;
            margin-top: 15px;
            @media screen and (max-width:1200px){
                font-size: 38px;
                line-height: 55px;
              }
        }
    }
}