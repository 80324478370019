  @media (max-width: 768px) {
    .hide-sidebar{
      display: none;
    }}
.iconbg {
  color: #000;
font-size: 30px;
margin-right: 25px;
margin-top: 10px;
margin-left: 10px;

}
.chat-sidebar {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: transparent;
  color: white;
  padding: 20px;

  .userProfile {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .avatar {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 10px;
      cursor: pointer;
    }

    .user-info {
      h5,
      p {
        margin: 0;
        color: #000;
      }
    }
  }

  .chat-lists {
    flex-grow: 1;
    overflow-y: auto;
    margin-top: 45px;

    .history {
      background: #f5f5f5;
      padding: 15px;
      border-radius: 15px;
      margin-bottom: 20px;
      .chat-title {
        color: #000000c2;
        font-size: 14px;
        font-weight: 500;
      }

      .chat-items {
        .chat-item {
          padding: 8px 10px;
          margin: 10px 0;
          cursor: pointer;
          background: #fff;
          border-radius: 10px;
          font-size: 16px;
          color: #000;
          &:hover {
            background-color: #57c1ff;
            color: #fff;
          }
        }
      }
    }
  }

  .new-chat-button {
    background-color: #57c1ff;
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 10px;
    font-weight: 800;
    margin-top: auto; // Sticks to the bottom
    margin-bottom: 65px;
    cursor: pointer;
    width: 100%;
    &:hover {
      background-color: darken(#57c1ff, 10%);
    }
  }
}
.modal {
  .modal-content {
    background-color: black;
    border: 1px solid white;
    color: #fff;
    border-radius: 20px;

    .modal-title {
      width: 100%;
    }

    .btn-close {
      display: none;
    }

    .modal-body {
      img {
        margin-bottom: 25px;
        border-radius: 100px;
        width: 100px;
      }
    }

    .form-group {
      margin-bottom: 15px;
      label {
        font-size: 18px;
        margin-bottom: 5px;
        font-weight: bold;
        font-family: "Space Grotesk", sans-serif;
      }

      input,
      textarea {
        width: 100%;
        padding: 15px;
        font-size: 14px;
        background-color: #000;
        color: #fff;
        border: 1px solid #dddd;
        border-radius: 5px;
        outline: 0;
      }
    }
    .upgrade {
      background-color: #57c1ff;
      color: #000;
      border: none;
      font-weight: 600;
      box-shadow: none;
      &:focus {
        background-color: #57c1ff;
        color: #000;
      }
    }
    .close {
      padding: 10px 20px;
      font-size: 1rem;
      background-color: #ffff;
      color: #fff;
      border: 2px solid #fff;
      border-radius: 10px;
      cursor: pointer;
      width: 100%;
      font-weight: 600;

      &:hover {
        background-color: #57c1ff;
        color: #000;
        border: 2px solid #000;
      }
    }
  }
}
