.footer{
    padding: 70px 0 20px;
    overflow: hidden;
    background-image: url(../../../public/images/footer.svg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @media screen and (max-width:992px){
        padding: 50px 0;
       }
    .logo{
        width: 248px;
        margin-bottom: 15px;
    }
    p{
        font-size: 14px;
        color: #000000c2;
        margin-bottom: 30px;
        max-width: 300px;
    }
    .social{
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .links{
        h4{
            font-size: 18px;
            font-weight: bold;
            color: #000;
            margin-bottom: 20px;
        }
        ul{
            padding: 0;
            margin: 0;
            list-style: none;
            @media screen and (max-width: 992px){
                margin-bottom: 30px;
              }
            li{
                margin-bottom: 15px;
                button,a{
                color: #000000c2;
                font-size: 14px;
                text-transform: capitalize;
                background-color: transparent;
                border: 0;
                outline: 0;
                padding: 0;
                &:hover{
                    color: #000;
                }
                }
            }
        }
        .formGroup{
            position: relative;
            width: 100%;
            max-width: 267px;
            margin-bottom: 20px;
            input{
                width: 100%;
                padding: 10px 15px;
                border: 2px solid #424244;
                border-radius: 5px;
                background-color: transparent;
                color: #fff;
                font-size: 14px;
                outline: 0;
                color: #424244;
                &::placeholder{
                    color: #000000c2;
                }
            }
            button{
                background: #57C1FF;
                color: #fff;
                border: none;
                padding: 10px 12px;
                border-radius: 5px;
                font-size: 14px;
                cursor: pointer;
                position: absolute;
                right: 0;
                top: 49%;
                transform: translateY(-50%);
            }
        }
    }
    .copyRights{
        text-align: center;
        margin-top: 70px;
        p{
            font-size: 14px;
            color: #000000c2;
            margin-bottom: 0;
            max-width: 100%;
            span{
                color: #57C1FF;
            }
        }
    }
}