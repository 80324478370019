.experienced{
    padding: 40px 0 60px;
    background-color: #fff;
    background-image: url(../../../../public/images/back.png);
    background-size: cover;
    position: relative;
    overflow: hidden;
    @media screen and (max-width:992px){
       padding: 50px 0;
      }
    .innerSection{
        background-color: #F5F5F5;
        border-radius: 20px;
        border: 1px solid #2e2f450a;
        padding: 70px 0 0;
        overflow: hiddens;
        .banner_content{
            .title{
                max-width: 826px;
                margin: 0 auto;
                text-align: center;
                position: relative;
                h2{
                    font-size: 40px;
                    font-weight: 700;
                    color: #000;
                    margin: 0;
                    @media screen and (max-width: 992px){
                        font-size: 32px;
                      }
                      span{
                        color: #57C1FF;
                      }
                }
                button{
                    background: #57C1FF;
                    color: #fff;
                    border: none;
                    padding: 10px 20px;
                    border-radius: 10px;
                    font-size: 18px;
                    font-weight: 500;
                    margin: 40px auto 0;
                    display: block;
                    cursor: pointer;
                    @media screen and (max-width: 992px){
                        font-size: 16px;
                      }
                    &:hover{
                        background: #31aff8;
                    }
                }
                img{
                    position: absolute;
                    z-index: 99;
                    left: 65%;
                    bottom: -60%;
                
                }
            }
            .bannerImg{
                text-align: center;
                position: relative;
                img{
                    max-width: 100%;
                    height: auto;
                    margin-top: 80px;
                    @media screen and (max-width: 992px){
                        margin-top: 50px;
                      }
                    }
                    &::after{
                      content: '';
                      position: absolute;
                      top: -23%;
                      left: 0;
                      width: 100%;
                      height: 100%;
                      background: url(../../../../public/images/experiencedShadow.png);
                      background-repeat: no-repeat;
                      background-repeat: no-repeat;
                      background-size: 100% 100%;
                      z-index: -1;
                    }
            }
        }
    }

}