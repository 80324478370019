.pricingCards{
    background-color: #fff;
      padding: 70px 0;
      background-image: url(../../../../public/images/back.png);
      background-size: cover;
      @media screen and (max-width:992px){
          padding: 50px 0;
         }
      .title{
          text-align: center;
          margin:0 auto 40px;
          p{
              font-size: 20px;
              color: #000000c2;
              margin: 0 auto 25px;
              span{
                    color: #57C1FF;
                    display: inline;
              }
          }
      }
      .tabsBlock{
            .nav-tabs{
                width: fit-content;
                margin: 0 auto 50px;
                background: #F5F5F5;
                color: #fff;
                padding: 10px;
                border: 1px solid #d7d7d7;
                border-radius: 10px;
                font-size: 18px;
                font-weight: 500;
                cursor: pointer;
                .nav-item{
                    margin-bottom: 0;
                    .nav-link{
                        color: #fff;
                        opacity: 1;
                        margin-right: 0;
                        border: 0;
                        background: transparent;
                        border-radius: 10px;
                        padding: 10px 15px !important;
                        &.active{
                            color: #fff !important;
                            background-color: #57C1FF;
                        }
                    }
                
                }
            }
      }
     .cards{
      .card{
          position: relative;
          background: #fff;
          border-radius: 15px;
          padding: 20px 30px ;
          border: 1px solid #d6d6d6;
          cursor: pointer;
          height: 100%;
          transition: all 0.3s ease-in-out;
          &:hover{
              border: 1px solid #57C1FF;
              transform: scale(1.05);
              .popular{
                    background-color: #57C1FF;
              }
          }
          .popular{
              background: #F5F5F5;
              color: #000;
              position: absolute;
              right: 0;
              border-top-left-radius: 9px;
              border-bottom-left-radius: 9px;
              padding: 3px 7px;
              font-size: 14px;
              img{
                    width: 110px;
              }
          }
          .price{
              color: #000;
              font-size: 62px;
              line-height: normal;
              margin-bottom: 20px;
              font-weight: 600;
              @media screen and (max-width:1200px){
                  font-size: 48px;
                }
          }
          .card-title{
              color: #57C1FF;
              font-size: 28px;
              margin-bottom: 20px;
              line-height: 32px;
              @media screen and (max-width:1200px){
                  font-size: 26px;
                }
          }
          .card-text{
              font-size: 18px;
              color: #000;
              margin-bottom: 20px;
              @media screen and (max-width:1200px){
                  font-size: 16px;
                }
          }
          button{
              width: 100%;
              height: 60px;
              background-color: #F5F5F5;
              color: #000;
              font-size: 20px;
              border-radius: 15px;
              font-weight: 500;
              border: 0;
              outline: 0;
              &:hover{
                  background-color: #57C1FF;
                  color: #fff;
              }
          }
          ul{
              list-style: none;
              margin: 0;
              li{
                  color: #000000c2;
                  margin-top: 20px;
                  .numbers{
                      color: #000;
                        font-weight: 500;
                  }
              }
          }
      }
     }
  }