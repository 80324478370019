.exit-logo {
  position: absolute;
  margin-right: 40px;
  margin-top: 50px;
  top: 0; /* Aligns the image to the top */
  right: 0; /* Aligns the image to the right */
  width: 35px; /* Adjust width as necessary */
  height: auto; /* Maintain aspect ratio */
}
.error-message {
  display: flex;
  background-color: rgb(254, 170, 184);
  padding: 10px;
  align-items: center;
  font-size: 15px;
  font-weight: bold;
  border-radius: 5px;
}
.success {
  background-color: rgb(164, 248, 164);
  padding: 15px;
}
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  background-image: url(../../../public/images/back.png);
  background-size: cover;
  margin-top: -80px;
  .block {
    width: 50%;
    position: relative;
    @media screen and (max-width: 992px) {
      display: none;
    }
    .back {
      width: 100%;
      height: auto;
      object-fit: cover;
      padding: 20px;
    }
  }
  .loginForm {
    width: 50%;
    max-width: 470px;
    margin: 0 auto;
    @media screen and (max-width: 1200px) {
      padding: 40px 0;
    }
    @media screen and (max-width: 992px) {
      padding: 50px 10px;
      width: 100%;
    }
    .title {
      max-width: 331px;
      margin: 0 auto;
      h3 {
        margin-bottom: 25px;
        text-align: center;
      }
    }
    .buttons {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      gap: 15px;
      button {
        padding: 12px 10px;
        font-size: 16px;
        background-color: #f5f5f5;
        color: #000;
        border: 1px solid #454668;
        border-radius: 7px;
        cursor: pointer;
        display: flex;
        font-weight: 500;
        justify-content: center;
        align-items: center;
        img {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
        &:hover {
          background-color: #fff;
          border: 1px solid #454668;
          color: #000;
        }
      }
    }
    .or {
      margin: 40px 0;
      p {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #454668;
        line-height: 0.1em;
        margin: 10px 0 20px;
        span {
          background: #fff;
          color: #7e8091;
          padding: 0 10px;
        }
      }
    }
    .form-group {
      margin-bottom: 15px;
      position: relative;
      input {
        width: 100%;
        padding: 15px 20px 15px 50px;
        font-size: 14px;
        border: 1px solid #454668;
        border-radius: 7px;
        color: #000;
        outline: 0;
        background: #ffff;
        &:focus {
          background: #ffff;
          color: #000;
        }
      }
      input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px #ffff inset;
        -webkit-text-fill-color: #000;
      }
      img {
        position: absolute;
        left: 15px;
        top: 50%;
        width: 25px;
        transform: translateY(-50%);
      }
    }
    .forget {
      margin-bottom: 40px;
      margin-top: 10px;
      a {
        color: #57c1ff;
        font-size: 14px;
      }
    }
    .loginBtn {
      padding: 10px 20px;
      font-size: 1rem;
      background-color: #57c1ff;
      color: #fff;
      border: 2px solid #57c1ff;
      border-radius: 7px;
      cursor: pointer;
      width: 100%;
      font-weight: 600;
      &:hover {
        background-color: #fff;
        color: #57c1ff;
        border: 2px solid #57c1ff;
      }
    }
    .register {
      text-align: center;
      margin-top: 30px;
      color: #7e8091;
      a {
        color: #57c1ff;
        font-size: 14px;
        font-weight: 500;
        text-decoration: underline !important;
      }
    }
  }
}
