
/* From Uiverse.io by adamgiebl */ 
.dots-container {
  display: flex;
  align-items: center;
  justify-content: left;
  height: 10vh;
  width: 100%;
}

.dot {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  border-radius: 10px;
  background-color: #b3d4fc;
  animation: pulse 1.5s infinite ease-in-out;
}

.dot:last-child {
  margin-right: 0;
}

.dot:nth-child(1) {
  animation-delay: -0.3s;
}

.dot:nth-child(2) {
  animation-delay: -0.1s;
}

.dot:nth-child(3) {
  animation-delay: 0.1s;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    background-color: #b3d4fc;
    box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
  }

  50% {
    transform: scale(1.2);
    background-color: #6793fb;
    box-shadow: 0 0 0 10px rgba(178, 212, 252, 0);
  }

  100% {
    transform: scale(0.8);
    background-color: #b3d4fc;
    box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
  }
}
@media (min-width: 768px){
.no-scroll {
  height: 100vh;
  overflow: hidden;
}
}


.chatBot {
  margin-top: -120px;
  max-height: 100vh;
  background-image: url(../../../public/images/back.png);
  background-size: cover;
  z-index: 2; 

  .messages-container {
    padding: 20px;
    background-color: transparent;
    height: calc(100vh - 190px);
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;

    .messages-list {
      margin-top: 30px;
      overflow-y: auto;
      width: 100%;
      max-height: 100%; // Ensure messages list doesn't exceed container height
      padding-right: 10px; // Add some padding for better UX

      .message-item {
        display: flex;
        padding: 10px;
        background: transparent;

        &.user .message-content {
          margin-left: auto;
          text-align: right;
          display: flex;
          flex-direction: row-reverse;
          gap: 10px;
          width: 100%;
        }

        &.bot .message-content {
          margin-right: auto;
          text-align: left;
          display: flex;
          gap: 10px;
          width: 100%;

          .message-actions {
            display: flex;
            color: #666;
            justify-content: flex-end;
            margin-top: 20px;
            width: 100%;

            p {
              margin: 0 3px;
              background-color: white;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 10px;
              width: 35px;
              height: 35px;

              img {
                width: 15px;
                height: 15px;
                cursor: pointer;
                filter: brightness(0.5);

                &:hover {
                  color: #fff;
                }
              }
            }
          }
        }

        &.bot {
          .message-bubble {
            background-color: #f5f5f5; // Bot's message color
            color: #000;
          }
        }

        .avatar {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          border: 1px solid #ccc;
        }

        .message-bubble {
          padding: 15px;
          border-radius: 10px;
          background-color: #57c1ff; // User's message color
          color: #fff;
          width: 91.5%;
        }
      }
    }
  }

  .regenerate {
    color: #c5c5c5;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-right: 10px;
      width: 16px;
    }
  }

  .input-area {
    padding: 10px;
    display: flex;
    align-items: center;
    position: relative;
    margin: 0 10px;
    margin-top: 20px;

    form {
      display: flex;
      width: 100%;

      .input-field {
        padding: 20px;
        border-radius: 15px;
        border: none;
        background-color: #f5f5f5;
        color: #000000;
        margin-right: 10px;
        outline: 0;
        width: 100%;

        &::placeholder {
          color: #000000;
        }
      }

      .send-button {
        background-color: #57c1ff;
        border: none;
        padding: 20px 20px;
        border-radius: 0 15px 15px 0;
        color: #fff;
        cursor: pointer;
        position: absolute;
        right: 10px;

        &:hover {
          background-color: darken(#57c1ff, 10%);
        }

        img {
          width: 20px;
        }
      }
    }
  }

  .cardsBlock {
    width: 100%;

    .card-button {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      border-radius: 1rem;
      border: 1px solid #e0e0e0;
      padding: 1rem 0.75rem 1.5rem;
      text-align: start;
      transition: background-color 0.2s;
    }

    .card-button:hover {
      border: 1px solid #e0e0e0;
    }

    .icon-md {
      width: 24px;
      height: 24px;
    }

    .line-clamp-3 {
      display: -webkit-box;
      // -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #000;
    }
  }

  // Responsive Styles
  @media (max-width: 768px) {
   
    .chatBot {
     
      .row {
        flex-direction: column !important;
      }

      .col-md-3,
      .col-md-9 {
        width: 100%;
        padding: 0;
      }

      .input-area {
        .input-field {
          padding: 10px;
        }

        .send-button {
          padding: 10px 15px;
        }
      }

      .message-bubble {
        padding: 10px;
      }

      .message-actions img {
        width: 30px;
        height: 30px;
        padding: 7px;
      }

      .avatar {
        width: 30px;
        height: 30px;
      }
    }

    .chat-sidebar {
      .userProfile .avatar {
        width: 40px;
        height: 40px;
      }

      .new-chat-button {
        padding: 8px;
      }
    }
  }
}
