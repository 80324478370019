.navbar{
  padding: 20px 0 !important;
  transition: all 0.5s;
  background-color: #ffff;
}
.navbar-collapse {
    flex-grow: inherit !important;
}
.navbarMenu{
  display: flex;
  align-items: center;
  gap: 15px;
}
.navbar-nav{
  align-items: center;
}
a{
  text-decoration: none;
}
.chat-link {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: #fff;
  border: 1px solid #0b0d0e;
  color: black; /* Default color when not active */
  padding: 7px;
  transition: all 0.5s;
  width: 138px;
  height: 50px;
  
  svg {
    margin-left: 10px;
    width: 20px;
    height: 20px;
  }

  &:hover {
    box-shadow: 0px 1px 13px 5px rgba(226, 226, 226, 0.3);
    background-color: #31aff81b;
  }

  &:active {
    background-color: #31aff81b;
  }

  /* When active, change the color to blue */
  &.active {
    color: #57C1FF;
    border: 1px solid #57C1FF;

  }
}

  
  .nav-link {
    transition: all 0.3s !important;
    
    position: relative;
    font-family: "Space Grotesk", sans-serif;
    font-size: 16px !important;
    font-style: normal;
    line-height: normal;
    font-weight: 400 !important;
    color: #000000 !important;
    padding: 5px 0 !important;
    opacity: 1;
    margin-right: 50px;
  }
  .nav-link.active {
    font-weight: 500 !important;
    opacity: 1;
    color: #57C1FF !important;
  }
  
  .nav-link span {
    transition: all 0.3s;
    display: flex;
    align-items: center;
    gap: 5px;

  }

  .nav-link:hover span {
    color: #57C1FF;
  }
  .nav-link:hover{
    opacity: 1;
  }
  .nav-link:hover::before {
    opacity: 1;
    transform: scale(1, 1);
  }
  

  .navbar.scrolling{
    background-color: #ffff;
    box-shadow: 0px 1px 13px 5px rgb(110 110 110 / 30%);
    padding: 15px 0;
    transition: all 0.5s;
  }
  .nav-link:hover::after {
    opacity: 0;
    transform: scale(0.1, 1);
  }
  .lap_btn{
    display: none;
  }
  .mbl_btn{
    display: block;
    text-decoration: none;
  }
  .booking{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: #57C1FF;
    border: 1px solid #57C1FF;
    color: #fff;
    padding: 7px;
    transition: all 0.5s;
    width: 138px;
    height: 50px;
    img{
      margin-right: 5px;
      width: 14px;
    }
    &:hover{
      box-shadow: 0px 1px 13px 5px rgba(226, 226, 226, 0.3);
      background-color: #31aff8;
    }
  }
  .customToggle{
    border: 0 !important;
    padding: 0 !important;
    outline: 0;
    box-shadow: none;
    .navbar-toggler-icon{
      display: none;
    }
   &::after{
     content: "";
     position: absolute;
     top: 15px;
     right: 5%;
     width: 40px;
     height: 40px;
    background: url(../../assets/images/toggler.svg) no-repeat;
    filter: brightness(0.5);
   }
  }
  .cross{
    border: 0 !important;
    padding: 0 !important;
    outline: 0;
    box-shadow: none;
    &.navbar-toggler{
      border: 0 !important;
      padding: 0 !important;
      outline: 0;
      box-shadow: none;
    }
    .navbar-toggler-icon{
      display: none;
    }
   &::after{
     content: "";
     position: absolute;
     top: 25px;
     right: 6%;
     width: 22px;
     height: 25px;
    background: url(../../assets/images/cross.svg) no-repeat;
    filter: brightness(0.5);
   }
  }
  @media screen and (max-width: 991px) {
    .navbar-collapse{
      position: absolute;
      background: #ffff;
      width: 100%;
      height: 100vh !important;
      top: 0;
      left: 0;
      padding: 20px;
    }
    .navbar-nav{
      gap: 30px;
      margin-top: 45px;
      .lap_btn{
        display: block;
      }
      .nav-link.active{
        background: #57C1FF;
        border-radius: 10px;
        padding: 15px 20px !important;
        font-family: "Space Grotesk", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px;
        letter-spacing: -0.5px;
        color: #ffff !important;
        border: none;
        cursor: pointer;
        transition: background-size 0.4s ease-in-out;
        &::after, &::before{
          display: none;
        }
      }
      .nav-link{
        padding: 5px 20px !important;
        &::after, &::before{
          display: none;
        }
      }
      .nav-link.active:hover {
        background-size: 110%;
      }
    }
  }
  @media screen and (max-width: 991px) {

  .mbl_btn{
    display: none;
  }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav {
        // gap: 60px;
    }
}